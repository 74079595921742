import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/imodium-behandelen-diarree/">
                  {" "}
                  IMODIUM® Geneesmiddelen{" "}
                </a>
              </li>
              <li>
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  IMODIUM® Instant Smelttablet
                </a>
              </li>
              <li className="last">
                <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-">
            <h2 />
            <h3>
              Welke IMODIUM<sup>®</sup> kiezen voor de symptomatische
              behandeling van diarree?
            </h3>
            <div className="enquete">
              <span className="arrow">&nbsp;</span>
              <div className="flexslider questions">
                <ul className="slides">
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Vraag 1/3:</strong>
                    </p>
                    <p>
                      <i>
                        Gaat je diarree gepaard met een van de volgende
                        symptomen?
                      </i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Gelieve de vraag te beantwoorden, vooraleer je verder
                        gaat.
                      </p>
                      <label htmlFor="radio1">
                        <input
                          name="check01"
                          type="radio"
                          id="radio1"
                          defaultValue="1"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Opgezette buik
                      </label>
                      <label htmlFor="radio2">
                        <input
                          name="check01"
                          type="radio"
                          id="radio2"
                          defaultValue="2"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Krampen
                      </label>
                      <label htmlFor="radio3">
                        <input
                          name="check01"
                          type="radio"
                          id="radio3"
                          defaultValue="3"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Winderigheid
                      </label>
                      <label htmlFor="radio4">
                        <input
                          name="check01"
                          type="radio"
                          id="radio4"
                          defaultValue="4"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Geen van bovenstaande
                      </label>
                      <div className="cf">
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Volgende vraag
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Vraag 2/3:</strong>
                    </p>
                    <p>
                      <i>
                        Ben je op zoek naar een snelwerkend middel tegen
                        diarree?
                      </i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Gelieve de vraag te beantwoorden, vooraleer je verder
                        gaat.
                      </p>
                      <label htmlFor="radio7">
                        <input
                          name="check3"
                          type="radio"
                          id="radio7"
                          defaultValue="1"
                          className="question3"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Ja
                      </label>
                      <label htmlFor="radio8">
                        <input
                          name="check3"
                          type="radio"
                          id="radio8"
                          defaultValue="2"
                          className="question3"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Nee
                      </label>
                      <div className="cf">
                        <a href="#" className="left previous-question">
                          Terug
                        </a>
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Volgende vraag
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Vraag 3/3:</strong>
                    </p>
                    <p>
                      <i>Vind je het moeilijk om pillen te slikken?</i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Gelieve de vraag te beantwoorden, vooraleer je verder
                        gaat.
                      </p>
                      <label htmlFor="radio9">
                        <input
                          name="check4"
                          type="radio"
                          id="radio9"
                          defaultValue="1"
                          className="question4"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Ja
                      </label>
                      <label htmlFor="radio10">
                        <input
                          name="check4"
                          type="radio"
                          id="radio10"
                          defaultValue="2"
                          className="question4"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Nee
                      </label>
                      <div className="cf">
                        <a href="#" className="left previous-question">
                          Terug
                        </a>
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Antwoorden
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <div className="results"></div>
                  </li>
                </ul>
              </div>
              <div className="row preview">
                <div className="six columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/JNJ_ImodiumInstandSmelttablet_thumb.png"
                      width="144"
                      height="80"
                      style={{
                        border: "none"
                      }}
                    />
                  </div>
                  <a href="/imodium-behandelen-diarree/imodium-instant/">
                    IMODIUM® Instant Smelttablet
                  </a>
                </div>
                <div className="six columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/JNJ_ImodiumCapsules_thumb.png"
                      width="144"
                      height="80"
                      style={{
                        border: "none"
                      }}
                    />
                  </div>
                  <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                    IMODIUM® Capsules
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
